const InstructorMan = () => {
  return (
    <div>
      <svg
        width="150"
        height="150"
        viewBox="0 0 1300 1300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M650 1300C290.71 1300 0 1008.86 0 650C0 290.71 291.141 0 650 0C1009.29 0 1300 291.141 1300 650C1300.43 1009.29 1009.29 1300 650 1300Z"
          fill="#B5E2DC"
        />
        <mask
          id="mask0_26_635"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1301"
          height="1300"
        >
          <path
            d="M650 1300C290.71 1300 0 1008.86 0 650C0 290.71 291.141 0 650 0C1009.29 0 1300 291.141 1300 650C1300.43 1009.29 1009.29 1300 650 1300Z"
            fill="#F7C0B5"
          />
        </mask>
        <g mask="url(#mask0_26_635)">
          <path
            d="M1014 1197.33C1010.97 1199.5 1007.5 1201.66 1004.47 1203.83C1001 1206 997.967 1208.16 994.5 1209.9C991.467 1211.63 988.867 1213.36 985.833 1215.1C985.4 1215.53 984.967 1215.53 984.533 1215.96C974.567 1222.03 964.167 1227.66 953.767 1232.86C950.733 1234.6 947.267 1236.33 944.233 1237.63C944.233 1237.63 943.8 1238.06 943.367 1238.06C939.9 1239.8 936.433 1241.53 932.967 1243.26C926.033 1246.73 918.667 1249.76 911.733 1252.8C908.267 1254.53 904.367 1255.83 900.9 1257.13C897.433 1258.43 893.533 1260.16 890.067 1261.46C886.6 1262.76 882.7 1264.5 879.233 1265.8C879.233 1265.8 879.233 1265.8 878.8 1265.8C875.333 1267.1 871.433 1268.4 867.967 1269.7C860.167 1272.3 851.933 1274.9 844.133 1277.5C841.533 1278.36 838.5 1279.23 835.9 1280.1C827.667 1282.7 819.433 1284.86 811.2 1286.6C808.167 1287.46 805.133 1287.9 801.667 1288.76C793.867 1290.5 785.633 1292.23 777.833 1293.96C776.1 1294.4 774.8 1294.4 773.067 1294.83C770.467 1295.26 767.433 1295.7 764.833 1296.13C760.933 1296.56 757.467 1297.43 753.567 1297.86C751.833 1298.3 750.1 1298.3 748.367 1298.73C746.2 1299.16 743.6 1299.6 741 1299.6C734.067 1300.46 726.7 1301.33 719.767 1302.2C717.6 1302.63 715 1302.63 712.833 1303.06H712.4C709.367 1303.5 706.333 1303.5 703.3 1303.93C700.267 1304.36 697.233 1304.36 694.2 1304.36C692.467 1304.36 690.3 1304.8 688.567 1304.8C685.1 1304.8 682.067 1305.23 679.033 1305.23C674.7 1305.23 670.8 1305.66 666.467 1305.66C662.133 1305.66 658.233 1305.66 653.9 1305.66C650.867 1305.66 647.833 1305.66 644.8 1305.66C462.367 1303.06 297.7 1225.5 180.7 1102.43C180.7 1102.43 180.7 1102 180.267 1102C193.7 1075.56 243.534 1027.9 295.534 988.465C361.4 938.632 454.567 907.865 454.567 907.865C454.567 907.865 515.667 897.898 742.3 945.132C915.2 981.532 988.867 1129.3 1014 1197.33Z"
            fill="#F7D3B5"
          />
          <path
            d="M790.4 1065.16C781.3 1109.8 722.367 1136.23 623.134 1096.36C501.367 1046.96 454.567 907.865 454.567 907.865C448.067 808.198 432.9 722.832 432.9 722.832C573.734 710.698 740.134 875.365 740.134 875.365L759.2 956.832C760.934 963.765 764.4 969.832 769.6 974.165C778.7 989.765 796.467 1036.56 790.4 1065.16Z"
            fill="#F7D3B5"
          />
          <path
            d="M769.6 974.599C686.833 970.265 625.733 958.132 560.3 840.699C615.767 889.665 681.2 908.299 750.1 916.965L759.2 956.832C760.933 964.199 764.4 970.265 769.6 974.599Z"
            fill="#EFBDA5"
          />
          <path
            d="M940.767 452.432C948.567 488.832 952.467 518.298 931.233 615.365C910 712.432 950.733 809.498 848.467 898.332C779.567 958.132 659.1 969.398 562.9 903.965C530.4 881.865 500.5 851.098 476.233 810.798C457.167 779.165 435.067 749.265 410.367 721.965C376.133 684.698 334.967 609.732 355.333 477.132C388.267 261.332 601.9 236.632 728 260.465C854.1 284.298 919.533 356.232 940.767 452.432Z"
            fill="#F7D3B5"
          />
          <path
            d="M526.067 621.865C526.067 621.865 512.633 556.865 449.367 564.665C394.767 571.598 375.7 702.465 499.633 738.865"
            fill="#F7D3B5"
          />
          <path
            d="M495.734 679.498C495.734 679.498 480.134 669.532 481.867 652.198C484.467 631.832 481.867 619.265 472.334 615.365C448.501 605.398 443.734 633.132 444.601 636.598"
            stroke="#423232"
            stroke-width="13"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M826.367 721.532C826.367 721.532 842.833 722.398 858.433 703.765C867.1 693.365 874.467 680.365 869.7 676.465C864.933 672.132 786.5 684.265 781.3 694.665C776.1 705.065 806.433 721.532 826.367 721.532Z"
            fill="#EFBDA5"
          />
          <path
            d="M839.367 575.932C839.367 575.932 840.234 617.098 866.667 663.032C878.367 683.398 860.601 716.765 822.034 722.398"
            stroke="#423232"
            stroke-width="13"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            opacity="0.42"
            d="M604.501 743.632C629.151 743.632 649.134 727.141 649.134 706.799C649.134 686.456 629.151 669.965 604.501 669.965C579.85 669.965 559.867 686.456 559.867 706.799C559.867 727.141 579.85 743.632 604.501 743.632Z"
            fill="#ED938A"
          />
          <path
            opacity="0.42"
            d="M924.3 670.832C923 695.098 923.434 718.932 921.7 742.765C902.634 738.432 888.767 724.132 888.767 707.232C888.767 689.032 903.933 674.298 924.3 670.832Z"
            fill="#ED938A"
          />
          <path
            d="M377.807 891.367C377.807 891.367 441.074 866.233 452.774 865.367L453.64 875.334C453.64 875.334 403.374 897 400.34 900.467C397.74 903.5 377.807 891.367 377.807 891.367Z"
            fill="#7E5D6D"
          />
          <path
            d="M1158.24 1453.4H62.7739C62.7739 1453.4 69.2739 1252.33 135.141 1108.9C184.974 1000.57 304.574 929.067 391.674 897.867C416.374 889.2 435.441 879.667 453.207 874.9C453.207 877.067 454.507 893.1 454.507 894.834C454.507 894.834 522.974 962 677.241 970.234C748.741 974.134 755.674 929.5 755.674 929.5C763.907 927.767 771.707 925.6 779.507 923C782.107 922.134 800.307 914.767 802.474 913.9C858.374 928.634 926.841 970.234 1026.07 1053.43C1138.31 1148.33 1158.24 1453.4 1158.24 1453.4Z"
            fill="#423232"
          />
          <mask
            id="mask1_26_635"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="62"
            y="874"
            width="1097"
            height="580"
          >
            <path
              d="M1158.24 1453.4H62.772C62.772 1453.4 69.272 1252.33 135.139 1108.9C184.972 1000.57 304.572 929.067 391.672 897.867C416.372 889.2 435.439 879.667 453.205 874.9C453.205 877.067 454.505 893.1 454.505 894.834C454.505 894.834 522.972 962 677.239 970.234C748.739 974.134 755.672 929.5 755.672 929.5C763.905 927.767 771.705 925.6 779.505 923C782.105 922.134 800.305 914.767 802.472 913.9C858.372 928.634 926.839 970.234 1026.07 1053.43C1138.31 1148.33 1158.24 1453.4 1158.24 1453.4Z"
              fill="#423232"
            />
          </mask>
          <g mask="url(#mask1_26_635)">
            <path
              d="M453.64 947.267C499.14 1011.4 620.474 1109.33 717.54 1127.97C763.907 1136.63 830.207 977.6 769.973 913.467C718.407 858.434 499.14 844.134 464.04 868.4C421.14 897 442.374 931.667 453.64 947.267Z"
              stroke="#FFFF8D"
              stroke-width="21.6667"
              stroke-miterlimit="10"
            />
          </g>
          <path
            d="M947.207 1012.7C975.208 1012.7 997.907 990.001 997.907 962C997.907 933.999 975.208 911.3 947.207 911.3C919.206 911.3 896.507 933.999 896.507 962C896.507 990.001 919.206 1012.7 947.207 1012.7Z"
            fill="#FFFF8D"
          />
          <path
            d="M277.274 1453.4L257.774 1293.07"
            stroke="#544D4D"
            stroke-width="13"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1033.87 1238.03C1033.87 1238.03 1020.87 1269.67 1024.77 1301.3C1028.67 1332.93 1039.94 1379.3 1023.91 1453.4"
            stroke="#544D4D"
            stroke-width="13"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M377.807 891.367C386.04 888.767 400.34 891.367 404.673 898.734C421.573 926.467 462.307 1007.5 479.207 1168.27C492.64 1293.93 472.273 1520.13 472.273 1520.13L51.5068 1500.2C51.5068 1500.2 64.0735 1286.57 100.907 1143.13C136.873 1004.03 231.34 941.634 377.807 891.367Z"
            fill="white"
          />
          <path
            d="M813.307 917.8C813.307 917.8 947.207 1169.57 908.207 1505.83H1197.24C1197.24 1505.83 1195.94 1238.9 1130.94 1093.73C1065.94 949 838.44 906.534 838.44 906.534C838.44 906.534 824.14 913.467 813.307 917.8Z"
            fill="white"
          />
          <path
            d="M813.306 917.8L802.906 914.767C802.906 914.767 818.073 907.4 824.573 903.067L828.473 900.9C828.473 900.9 849.273 910.434 856.64 913.9C864.006 917.367 831.073 933.4 831.073 933.4C831.073 933.4 814.606 917.8 813.306 917.8Z"
            fill="#7E5D6D"
          />
          <path
            d="M341.84 1040C341.84 1040 282.04 1082.03 287.673 1126.67C293.307 1171.3 477.473 1450.8 477.473 1450.8C477.473 1450.8 499.14 1299.13 474.44 1127.53C474.44 1127.53 456.673 1103.7 424.173 1079.87C402.507 1064.7 374.773 1048.67 341.84 1040Z"
            fill="#AD7E5A"
          />
          <path
            d="M1041.24 1126.67C1042.97 1098.93 978.84 1053.87 978.84 1053.87C953.274 1061.23 935.074 1072.93 922.074 1085.5C893.04 1112.8 889.14 1143.57 889.14 1143.57L890.874 1151.37C921.64 1285.27 912.54 1459.47 912.54 1459.47C912.54 1459.47 1039.51 1154.4 1041.24 1126.67Z"
            fill="#AD7E5A"
          />
          <path
            d="M474.007 1127.53C474.007 1127.53 443.674 1095.47 407.274 1072.93C326.674 1023.1 208.374 1000.13 207.074 961.567C206.207 918.234 350.074 827.667 445.407 796.9C445.407 797.334 445.407 797.767 445.407 798.2C445.407 798.634 445.407 799.067 445.407 799.934C445.407 800.8 445.407 801.667 445.841 802.534C445.841 803.834 446.274 805.134 446.274 806.434C446.274 807.3 446.274 807.734 446.707 808.6C449.741 834.6 452.341 863.634 454.507 894.4V894.834C454.507 894.834 434.574 901.334 448.874 1000.57C452.774 1024.83 467.507 1067.3 474.007 1127.53Z"
            fill="#AD7E5A"
          />
          <path
            d="M889.14 1141.4C889.14 1141.4 860.107 1028.73 845.807 992.767C831.507 957.233 804.64 916.933 803.34 914.767C820.24 906.967 835.407 897 848.84 885.3C862.274 873.6 873.107 861.9 882.207 850.2C930.74 875.767 1069.84 956.367 1076.77 996.667C1084.57 1040.43 916.007 1037.83 889.14 1141.4Z"
            fill="#AD7E5A"
          />
          <path
            d="M732.707 1204.67C760.708 1204.67 783.407 1181.97 783.407 1153.97C783.407 1125.97 760.708 1103.27 732.707 1103.27C704.706 1103.27 682.007 1125.97 682.007 1153.97C682.007 1181.97 704.706 1204.67 732.707 1204.67Z"
            fill="#FFFF8D"
          />
          <path
            d="M732.707 1179.97C747.066 1179.97 758.707 1168.33 758.707 1153.97C758.707 1139.61 747.066 1127.97 732.707 1127.97C718.347 1127.97 706.707 1139.61 706.707 1153.97C706.707 1168.33 718.347 1179.97 732.707 1179.97Z"
            fill="#FFBE71"
          />
          <path
            d="M978.84 1053.87C953.274 1061.23 935.074 1072.93 922.074 1085.5C893.04 1112.8 889.14 1143.57 889.14 1143.57"
            stroke="#894B33"
            stroke-width="13"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M474.006 1127.53C474.006 1127.53 456.24 1103.7 423.74 1079.87C402.506 1064.27 374.773 1048.67 341.406 1039.57"
            stroke="#894B33"
            stroke-width="13"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M683.943 619.439C673.543 619.006 665.743 610.339 666.61 599.939L667.043 587.806C667.476 577.406 676.143 569.606 686.543 570.473C696.943 570.906 704.743 579.573 703.876 589.973L703.443 602.106C703.01 612.073 694.343 619.873 683.943 619.439Z"
            fill="#423232"
          />
          <path
            d="M866.375 625.939C856.842 625.506 849.475 617.272 849.909 607.739L850.342 596.472C850.775 586.939 859.009 579.572 868.542 580.006C878.075 580.439 885.442 588.672 885.009 598.206L884.575 609.472C884.142 619.006 875.909 626.372 866.375 625.939Z"
            fill="#423232"
          />
          <path
            d="M725.977 594.306C725.977 594.306 714.71 578.273 695.21 575.239C679.177 572.639 661.41 580.439 658.81 589.539"
            stroke="#423232"
            stroke-width="13"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M853.809 598.206C853.809 598.206 853.809 584.339 873.743 584.339C890.209 584.339 897.143 599.506 897.143 599.506"
            stroke="#423232"
            stroke-width="13"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M743.743 524.539C752.843 527.573 761.942 519.339 760.209 509.373C759.342 504.173 757.176 498.973 752.843 495.073C739.843 483.373 686.109 472.973 653.609 488.139C625.009 502.006 702.143 510.673 743.743 524.539Z"
            fill="#423232"
          />
          <path
            d="M873.308 531.473C865.941 534.073 858.141 526.273 859.441 517.173C859.874 512.406 861.608 507.639 865.074 504.173C875.474 493.773 911.008 485.539 933.541 501.573C953.041 515.006 907.108 518.906 873.308 531.473Z"
            fill="#423232"
          />
          <path
            d="M806.697 784.149C806.697 784.149 789.364 763.782 717.864 784.149"
            stroke="#423232"
            stroke-width="13"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M974.206 347.827C943.44 459.194 854.173 440.56 770.54 410.227C659.173 369.494 561.24 402.427 560.806 502.96C559.94 650.294 523.106 605.66 519.206 600.027C509.673 581.827 489.306 559.294 450.74 564.06C400.04 570.56 380.106 682.36 474.573 728.294C491.04 811.927 445.973 804.127 445.973 804.127C430.806 742.594 303.406 611.727 302.973 525.494C302.973 409.36 361.473 376.427 361.473 376.427C363.206 295.394 447.706 206.127 589.84 209.16C731.973 211.76 819.073 207.86 846.806 174.494C874.539 140.26 1004.97 236.46 974.206 347.827Z"
            fill="#423232"
          />
          <path
            d="M977.673 481.727C973.34 509.894 961.206 531.56 944.306 547.594C950.806 500.36 947.34 477.827 941.706 451.394C929.573 397.227 903.573 350.427 860.24 315.76C881.906 314.027 904.006 318.794 923.94 331.36C923.94 330.927 995.44 359.96 977.673 481.727Z"
            fill="#423232"
          />
          <path
            d="M829.007 730.178C829.007 730.178 812.974 695.945 753.607 717.178C715.474 731.045 678.207 752.711 657.407 730.178C657.407 730.178 668.241 786.511 735.841 798.645C819.041 812.945 836.807 755.745 829.007 730.178Z"
            fill="#423232"
          />
          <path
            d="M825.54 724.978C825.54 724.978 850.24 701.578 874.074 723.678C895.74 743.611 906.14 746.645 913.94 734.511C913.94 734.511 915.674 782.178 877.974 788.245C836.807 795.178 821.64 762.245 821.64 762.245"
            fill="#423232"
          />
        </g>
      </svg>
    </div>
  );
};

export default InstructorMan;
